import { fieldTypes } from '@root/constants'
import { DATE_FIELD_TYPES } from '../../shared'
import { METRIC_FUNC } from '@reducers/dashboards/types'
import keyBy from 'lodash/keyBy'

const GROUP_BY_TYPES_TO_EXCLUDE = [fieldTypes.currency, fieldTypes.fileUpload]

export const filterGroupByOptions = (fields) => fields.filter(field => {
  const isNestedField = (field.value ?? field.systemKey).includes('.')
  return !GROUP_BY_TYPES_TO_EXCLUDE.includes(field.type) &&
    !(DATE_FIELD_TYPES.includes(field.type) && isNestedField)
})

export const filterNumericOptions = (fields) => {
  return fields.filter(field => [fieldTypes.number, fieldTypes.currency].includes(field.type))
}

export const filterSortByOptions = (fields, widget) => {
  const { groupBy } = widget.displayConfig

  const groupByFieldValue = groupBy?.field?.systemKey
  const groupByMetricFieldValue = groupBy?.metric?.field?.systemKey

  const fieldsOptionsByValue = keyBy(fields, 'value')
  const filteredFieldsOptions: Array<{ label: string, value: any }> = []

  fieldsOptionsByValue[groupByFieldValue] && filteredFieldsOptions.push(fieldsOptionsByValue[groupByFieldValue])

  if (groupBy?.metric?.func === METRIC_FUNC.TOTAL) {
    filteredFieldsOptions.push({ label: 'Count', value: `:${METRIC_FUNC.TOTAL}` })
  } else if (fieldsOptionsByValue[groupByMetricFieldValue]) {
    const field = fieldsOptionsByValue[groupByMetricFieldValue]
    filteredFieldsOptions.push({ ...field, label: field.value === groupByFieldValue ? `${field.label} (${groupBy.metric.func})` : field.label, value: `${field.value}:${groupBy.metric.func}` })
  }

  return filteredFieldsOptions
}

export const filterTableSortByOptions = (fields, widget) => {
  const selectedFields = widget?.displayConfig?.fields || []
  return fields.filter(field => selectedFields.includes(field.value))
}
